<template>
    <div class="footer-area-wrapper" :class="FooterStyle">
        <div class="footer-area section-space--ptb_90">
            <div class="container">
                <div class="row footer-widget-wrapper">
                    <div class="col-lg-4 col-md-6 footer-widget">
                        <div class="footer-widget__logo mb-30">
                            <router-link to="/">
                                <img src="../assets/img/logo/logo-dark.png" class="img-fluid logo-dark" alt="">
                                <img src="../assets/img/logo/logo-light.png" class="img-fluid logo-light" alt="">
                            </router-link>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 footer-widget">
                        <ul class="footer-widget__list">
                            <li><router-link to="/docs" class="hover-style-link">Documentation</router-link></li>
                            <li><router-link to="/downloads" class="hover-style-link">Downloads</router-link></li>
                            <li><a href="https://app.modeck.io/login" class="hover-style-link">Log in</a></li>
                            <li><a href="https://app.modeck.io/login?signup=1" class="hover-style-link">Sign up</a></li>
                            <li><a href="mailto:hello@modeck.io" class="hover-style-link">Contact us</a></li>
                            <li><a href="https://aescripts.com/modeck" target="_blank" class="hover-style-link">aescripts</a></li>
                            <li><router-link to="/privacy-policy" target="_blank" class="hover-style-link">Privacy Policy</router-link></li>
                            <li><router-link to="/terms-and-conditions" target="_blank" class="hover-style-link">Terms and Conditions</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-copyright-area border-top section-space--ptb_30">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 text-center text-md-left">
                        <span class="copyright-text">© 2025 MoDeck. All Rights Reserved.</span>
                    </div>
                    <div class="col-md-6 text-center text-md-right">
                        <ul class="list ht-social-networks solid-rounded-icon">
                            <li class="item">
                                <a href="https://www.facebook.com/modeck.io" target="_blank" class="social-link"> <i class="fab fa-facebook social-link-icon"></i> </a>
                            </li>
                            <li class="item">
                                <a href="https://twitter.com/modeck_io" target="_blank" class="social-link"> <i class="fab fa-twitter social-link-icon"></i> </a>
                            </li>
                            <li class="item">
                                <a href="https://www.instagram.com/modeck.io" target="_blank" class="social-link"> <i class="fab fa-instagram social-link-icon"></i> </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FooterMain',
        props: ["FooterStyle"]
    }
</script>